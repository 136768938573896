<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="'CONTACT US'"></h4>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table" />
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'ContactUs',

    data () {
        return {
            listUrl : urls.contactUs.list,
            fields  : [
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Name',
                    titleClass : ''
                },
                {
                    name       : 'email',
                    sortField  : 'email',
                    title      : 'Email',
                    titleClass : ''
                },
                {
                    name       : 'phone_number',
                    sortField  : 'phone_number',
                    title      : 'Phone Number',
                    titleClass : ''
                },
                {
                    name       : 'message',
                    sortField  : 'message',
                    title      : 'Message',
                    titleClass : ''
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
